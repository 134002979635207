













































import Component from 'vue-class-component';
import Vue from 'vue';
import Fight from '@/interfaces/Fight';

@Component
export default class Dashboard extends Vue {
  private fights: Fight[] = [];
  private fightsLoading: boolean = true;

  public created(): void {
    this.$store.dispatch('loadFights').then(() => {
      this.fights = this.$store.getters.getFights;
      this.fightsLoading = false;
    });
  }

  private showFight(id): void {
    this.$router.push('fight/' + id);
  }

  private getFightTitle(fight): string {
    // Concatenate the players together
    let allPlayers = '';
    let allEnemies = '';
    fight.fighters.forEach((fighter) => {
      if (fighter.type === 'enemy') {
        allEnemies += fighter.name + ', ';
      } else {
        allPlayers += fighter.short + ', ';
      }
    });
    return allPlayers + ' vs. ' + allEnemies;
  }

  private numberOfPlayersInFight(fight): number {
    let allPlayers = 0;
    fight.fighters.forEach((fighter) => {
      if (fighter.type !== 'enemy') {
        allPlayers++;
      }
    });
    return allPlayers;
  }

  private createdTime(fight): string {
    if (!fight.hasOwnProperty('created') || fight.created === 0) {
      return '';
    } else {
      const date = new Date(fight.created.seconds * 1000);
      return 'Erstellt: ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    }
  }

  private getFightSubtitle(fight): string {
    let allEnemies = '';
    fight.fighters.forEach((fighter) => {
      if (fighter.type === 'enemy') {
        allEnemies += fighter.short + ',';
      }
    });
    return allEnemies;
  }

  private userIsFightOwner(fight): boolean {
    if (this.$store.getters.getUser === null) {
      return false;
    }
    return fight.owner === this.$store.getters.getUser.uid;
  }

  private newFight(): void {
    // Create a new fight
    this.$store.dispatch('createFight').then((fightId) => {
      // Forward to the new fight
      this.$router.push('fight/' + fightId);
    });
  }

  private deleteFight(fightId): void {
    this.fightsLoading = true;
    this.$store.dispatch('deleteFight', fightId).then(() => {
      this.$store.dispatch('loadFights').then(() => {
        this.fights = this.$store.getters.getFights;
        this.fightsLoading = false;
      });
    });
  }

  private leaveFight(fightId): void {
    this.$store.dispatch('leaveFight', fightId);
  }
}
